import './styles/App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom"
import main from "./routes/main"
import articlePage from "./routes/articlePage"
import Maintenance from './routes/notfound';


function App() {
  return (
<BrowserRouter>
<Routes>
  <Route path="*" Component={Maintenance}/>
  <Route exact path="/" Component={main}/>
  <Route path="/article" Component={articlePage}/>
</Routes>
</BrowserRouter>
  );
}

export default App;
