import {useState, useEffect, } from 'react'
import Navbar from '../components/Navbar';
import Articles from '../components/Articles';
import Footer from '../components/Footer';
import titleImage from "../assets/img/articles/sustainableCooking.jpg"
const ArticlePage = () => {
    
    const [resized, setResized] = useState(window.innerWidth)
    useEffect(() => {
      window.addEventListener("resize", () => {
        setResized(window.innerWidth)
      })
    }, [resized])
  
    const styles = {
      titleContainer:{
        fontFamily:"Aloja",
        fontSize:(resized < 1005) ? 35 : 45,
        textAlign:"left",
        width:"100%",
        height:"fit-content",
        // transform: (resized < 1005) ? "scale(.8)" : "scale(1)"
      },
  
      innerContainer: {
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        margin:"0 auto",
        padding: (resized < 600) ? 20 : 100,
        paddingTop:100
      },
  
    }
return (

<div className="App">

     <Navbar resized={resized}/>
     <div style={styles.titleContainer}>
    <div style={styles.innerContainer}>
    <img src={titleImage} alt="titleImage"/>
    <p style={{fontSize:"100%", padding:0, marginBottom:0}}>
    Sustainable Cooking
    </p>

    <p style={{fontSize:"70%", padding:0, marginBottom:0}}>
    Delicious Recipes that are Good for You and the Planet
    </p>

    <p style={{fontSize:"50%", fontFamily:"Bodoni"}}>
Sustainable cooking has become a hot topic in recent years, and for good reason. It's not only good for the planet, but it can also be good for your health. By using fresh, locally-sourced ingredients and eco-friendly cooking methods, you can reduce your carbon footprint and create delicious, nutrient-dense meals.<br/><br/>
One of the best ways to start cooking sustainably is by using fresh, seasonal ingredients. When you buy produce that's in season, you're supporting local farmers and reducing the carbon emissions that come with transporting food long distances. Plus, seasonal produce is often more flavorful and nutrient-dense than out-of-season produce.<br/><br/>
Another way to reduce your environmental impact is by incorporating more plant-based meals into your diet. Plant-based meals require fewer resources to produce and are often more affordable than meat-based meals. Plus, they can be just as delicious and satisfying if you use the right ingredients and cooking methods.<br/><br/>
Finally, sustainable cooking is about using eco-friendly practices in the kitchen. This includes composting food scraps, reducing food waste, and using reusable containers and utensils. By making small changes in your kitchen, you can reduce your environmental impact and create a more sustainable future.<br/><br/>
In conclusion, sustainable cooking is a simple and delicious way to make a positive impact on the planet. By using fresh, seasonal ingredients, incorporating more plant-based meals, and using eco-friendly practices, you can reduce your carbon footprint and create meals that are good for you and the planet.
    </p>
      </div>
      </div>
    <Articles resized={resized}/>
    <Footer />
    </div>
)
}

export default ArticlePage;


