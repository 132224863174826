import Cards from './Cards'
import blueBerry from '../assets/img/cards/blueberry.png'
import grilledChicken from '../assets/img/cards/grilledchicken.png'
import stirFry from '../assets/img/cards/stirfry.png'

const Recipes = (props) => {

    return(
        <div style={{padding:10}}>
<p style={{fontFamily:"Aloja", textAlign:"center", marginTop:25, color:"black", fontSize: (props.resized > 600) ? 30 : 20}}>Easy-to-make organic Recipes </p>

<div style={{display:"flex", justifyContent:"space-evenly", flexDirection: (props.resized < 600) ? "column" : "row", width:"100%"}}>
<Cards 
resized={props.resized}
src={blueBerry}
title={"Blueberry Oatmeal"}
desc={"Start your day off right with a bowl of organic oats, blueberries, and honey. Yum!"} 
btnTitle={"Learn How"}
buttonWidth={110}
/>
<Cards 
resized={props.resized}
src={stirFry}
title={"Veggie Stir-fry"} 
desc={"Sautee up some organic veggies like bell peppers, zucchini, and broccoli in organic olive oil and soy sauce for a quick and tasty dinner."} 
btnTitle={"Learn How"}
buttonWidth={110}
/>
<Cards 
resized={props.resized}
src={grilledChicken}
title={"Grilled Chicken Salad"} 
desc={"Grill up some organic chicken and toss it with fresh organic lettuce, cucumber, and tomato for a healthy and satisfying lunch."} 
btnTitle={"Learn How"}
buttonWidth={110}
/>
</div>
</div>
    )

}

export default Recipes;