
import logo from "../assets/logo_black.png"
import shop from "../assets/img/organicshop.jpg"
import youtube from "../assets/img/social/youtube.png"
import instagram from "../assets/img/social/insta.png"
import tiktok from "../assets/img/social/tiktok.png"
import banner from '../assets/img/banner.png'

const Footer = () => {

    const styles = {
        container: {
            fontFamily: "Roboto",
            width:"100%",
            background:"#ffee3b",
            marginTop:-5,
            display:"flex",
            justifyContent:"flex-start",
            flexDirection:"column",
            paddingBottom:20,
        },
        navContent:{
            marginTop:20
        },
        innerSocial:{
            alignItems:"center", 
            display:"flex", 
            margin:4
        }
    }
return(
    <>
    <img src={banner} style={{marginBottom:-5}} width={"100%"} alt="banner"/>
    <img src={shop} width="100%" alt="organic Shop" />
    <div style={styles.container}>
    <div style={{padding:30, textAlign:"left"}}>
    <a href="/">
    <img src={logo} width={120} alt="logo"/>
    </a>
    <div style={{textAlign:"left"}}>
    <a href="/">
    <div style={styles.navContent}>Home</div>
    </a>
    <div style={styles.navContent}>About</div>
    <a href="/article">
    <div style={styles.navContent}>Articles</div>
    </a>
    <div style={styles.navContent}>Recipes</div>
    <div style={{...styles.navContent, display:"flex", flexDirection:"row", justifyContent:"flex-start"}}>
        <div style={styles.innerSocial}>
        <img src={youtube} width={30} alt="youtube" />
        </div>
        <div style={styles.innerSocial}>
        <a href="https://www.instagram.com/officialecocuisine/"><img src={instagram} width={24} alt="instagram" /></a>
        </div>
        <div style={styles.innerSocial}>
        <img src={tiktok} width={25} alt="tiktok" />
        </div>
    </div>

    </div>
    </div>
    <p style={{fontSize:12}}>&copy; 2024 Eco Cuisine - All Rights Reserved</p>
    </div>
    </>
    
)

}

export default Footer;