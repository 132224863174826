import jimmy from "../assets/img/jimmy.png"

const Quote = (props) => {
    
    const styles = {
    
        container: {
          display:"flex",
          padding:"20px 20px 0px 20px",
          alignItems:"center",
          background: "#ffee3b"
        },
    
        quote: {
          fontFamily:"Aloja",
          padding:20,
          fontSize: 
          (props.resized >= 600 && props.resized <= 700) ? 20 
          : (props.resized <= 600) ? 16 
          : 28,
          textAlign:"left",
          maxWidth:440,
        },
        max: {
          margin:"0 auto",
          display:"flex",
          width:"90%",
          alignItems:"flex-end",
          justifyContent:"center",
        },
    
        jimmy:{
          marginBottom:-4,
          width:(props.resized < 600) ? 140: 200,
          alignSelf:"flex-end"
        }
        
      }

    return(
<div style={styles.container}>
      <div style={styles.max}>
      <div>
        <img src={jimmy} alt={"jimmy"} style={styles.jimmy}/>
      </div>

      <div style={styles.quote}>
      {'"By choosing organic, you’re supporting a sustainable food system that’s better for both people and the planet."'}
      <br />
      <p style={{fontSize: (props.resized < 600) ? 10 : 18,}}>{"~Jimmy the Chef"}</p>
      </div>
      </div>
     </div>
    )
}


export default Quote;