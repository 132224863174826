import {useState, useEffect, } from 'react'
import Navbar from '../components/Navbar';
import HeaderVideo from '../assets/video/EcoCuisine_Video.mp4'
import Recipes from '../components/Recipes';
import Quote from '../components/Quote';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Articles from '../components/Articles';

const Main = () => {
    
    const [resized, setResized] = useState(window.innerWidth)
    useEffect(() => {
      window.addEventListener("resize", () => {
        setResized(window.innerWidth)
      })
    }, [resized])

return (
<div className="App">
     <Navbar resized={resized}/>
      <div
      style={{
        width:"100%",
        display:"flex"
      }}>

     <div style={{paddingTop:(resized < 600) ? 60 : 100, margin:"0 auto", width:(resized < 600) ? "100%" : "30%"}}>
     <video src={HeaderVideo} style={{
      borderRadius:(resized > 600) ? 10 : 0,
      borderLeft:(resized > 600) && "10px solid #ffee3b", 
      borderTop:(resized > 600) && "10px solid #ffee3b", 
      borderBottom:(resized > 600) && "10px solid #ffee3b",  
      borderRight:(resized > 600) && "10px solid #ffee3b"

     }} width="100%" controls={true} loop muted autoPlay playsInline/>
    </div>
      {(resized > 600) &&

    <div style={{ width:"calc(50% - 80px)",padding: 50, display:"flex", alignItems:"center", justifyContent:"center"}}>
      <p style={{padding:30, margin:0, fontFamily:"aloja",  fontSize:(resized < 600) ? 60 : 100}}>
        "Cook<br/>Ninja"
      </p>
     <div style={{position: "absolute", zIndex:-10, transform:"skew(-5deg)", background:"#ffee3b", margin:"0px", width:40, height:400}}></div>
    </div>
      }


    
      </div>
      
     {/* <Maintenance/> */}
    <Header resized={resized}/>
    <Articles resized={resized}/>
    <Quote resized={resized}/>
    <Recipes resized={resized}/>
    <Footer />
    </div>
)
}

export default Main;


