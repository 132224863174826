import {useRef, useEffect} from 'react' 
import gsap from 'gsap'

const Btn = (props) => {
    
    const btn = useRef()
    useEffect(() => {
        gsap.set(btn.current, {x:-props.width})
    },[])

    return(
    <div 
    onMouseEnter={() => {
        gsap.set(btn.current, {x:-props.width})
        gsap.to(btn.current, .3, {x:0})
    }}

    onMouseLeave={() => {
        gsap.to(btn.current, .3, {x:props.width})
    }}
    style={{
        width: props.width, 
        overflow:"hidden", 
        cursor:"pointer",
        transform:"scale("+props.scaler+")"
        }}>
    
    <div 
    ref={btn} 
    style={{
        height:30, 
        background:"#ffee3b", 
        rotate:"1deg", 
        marginBottom:9,
        padding:0,
        marginTop:0,
        width:props.width,
        
        }}>
    </div> 
    <p style={{
        fontSize:20, 
        fontFamily:"Aloja", 
        position:"relative", 
        width:props.width, 
        rotate:"-1deg", 
        padding:0, 
        marginTop:-30
        }}>{props.btnTitle}</p>
    </div>
    )
}

export default Btn;