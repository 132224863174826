import Cards from './Cards'
import sustain from '../assets/img/articles/sustainableCooking.jpg'
import leftover from '../assets/img/articles/creativeLeftovers.jpg'
import foodforthought from '../assets/img/articles/foodforthough.jpg'
import healthy from '../assets/img/articles/organicproduce.jpg'
import farmers from '../assets/img/articles/farmersmarket.jpg'
import immune from '../assets/img/articles/superfoodimmune.jpg'

const Articles = (props) => {

    return(
        <div style={{padding:10}}>
<p style={{fontFamily:"Aloja", textAlign:"center", marginTop:25, color:"black", fontSize: (props.resized > 600) ? 30 : 20}}>Articles </p>

<div style={{display:"flex", justifyContent:"space-evenly", flexDirection: (props.resized < 600) ? "column" : "row", width:"100%"}}>

<Cards 
resized={props.resized}
src={sustain}
title={"Sustainable Cooking"}
desc={"Delicious Recipes that are Good for You and the Planet."} 
btnTitle={"Read More"}
buttonWidth={110}
latest={true}
url={"/article"}
/>

<Cards 
resized={props.resized}
src={leftover}
title={"Creative With Leftovers"} 
desc={"Supercharge your food selection, learning how to upcycle."} 
btnTitle={"Read More"}
buttonWidth={110}
/>

<Cards 
resized={props.resized}
src={foodforthought}
title={"Food For thought"} 
desc={"Globally, approximately one-third of all food produced for human consumption is wasted annually."} 
btnTitle={"Read More"}
buttonWidth={110}
/>
</div>

<div style={{display:"flex", justifyContent:"space-evenly", flexDirection: (props.resized < 600) ? "column" : "row", width:"100%"}}>
<Cards 
resized={props.resized}
src={farmers}
title={"Farmers' Market"}
desc={"Hot spots for great produce, and your local farmers' market."} 
btnTitle={"Read More"}
buttonWidth={110}
/>
<Cards 
resized={props.resized}
src={immune}
title={"Happy immune system"} 
desc={"Top tips including 10 superfoods to boost your immune system."} 
btnTitle={"Read More"}
buttonWidth={110}
/>

<Cards 
resized={props.resized}
src={healthy}
title={"Organic Produce"} 
desc={"What to look for when sourcing your organic fruits and vegetables."} 
btnTitle={"Read More"}
buttonWidth={110}
/>
</div>

</div>
    )

}

export default Articles;