import {useState, useRef} from 'react'
import logo from '../assets/logo_black.png';
import classes from '../styles/smallNav.module.css'
import youtube from "../assets/img/social/youtube.png"
import instagram from "../assets/img/social/insta.png"
import tiktok from "../assets/img/social/tiktok.png"
import gsap from 'gsap'
function Navbar(props) {

  const burger = useRef()
  const [bool, setBool] = useState(false)

  const burgerClick = (e) => {
    e.preventDefault()
    setBool(!bool)
    if(bool){
    gsap.to(burger.current, .5, {left: "100vw"})
  }else{
    gsap.to(burger.current, .5, {left: "0vw"})
    }
  }

  const styles = {
    navbarStyle: {
     margin:20,
     fontFamily:"Roboto",
     cursor:"pointer",
     userSelect:"none",
     WebkitTapHighlightColor: "transparent"
    },
    menuSpan: {
      display:"block",
      background: "#000",
      height:3,
      width: 30,
      borderRadius:10
  },
  navContent:{
    marginTop:20
},
innerSocial:{
    alignItems:"center", 
    display:"flex", 
    margin:4
}

  }

  return (
    <>
    <div style={{width:"100%", position:"fixed", zIndex:9999}}>
      <div style={{width:"100%", display:'flex', alignItems:"center", justifyContent:"flex-end", flexDirection: 'row', background:"#ffee3b"}}>
      <div style={{width:"100%", justifyself:"flex-start", paddingLeft: 20, textAlign:"left"}}>
      <a href="/"><img height={40} src={logo} alt={"logo"}/></a>
        </div>
      {
        (props.resized > 600) ?
        <>
      <a href="/">
    <div style={styles.navbarStyle}>Home</div>
    </a>
      <div style={styles.navbarStyle}>About</div>
      <a href="/article">
      <div style={styles.navbarStyle}>Articles</div>
      </a>
      <div style={styles.navbarStyle}>Recipes</div>
        </>
        :
        <>
        <div onClick={burgerClick} style={{...styles.navbarStyle, userSelect:"none"}}>
        <span style={{...styles.menuSpan, marginTop:0}}></span>
        <span style={{...styles.menuSpan, marginTop:6}}></span>
        <span style={{...styles.menuSpan, marginTop:7}}></span>
       </div>
       </>
      }
     
      </div>
     
    </div>

<div ref={burger} className={`${classes.container}`}>

<a href="/">
    <div style={styles.navbarStyle}>Home</div>
    </a>
<div style={styles.navbarStyle}>About</div>
<a href="/article">
<div style={styles.navbarStyle}>Articles</div>
</a>
<div style={styles.navbarStyle}>Recipes</div>
<div style={{...styles.navContent, display:"flex", flexDirection:"row", justifyContent:"center"}}>
        <div style={styles.innerSocial}>
        <img src={youtube} width={30} alt="youtube" />
        </div>
        <div style={styles.innerSocial}>
        <a href="https://www.instagram.com/officialecocuisine/"><img src={instagram} width={24} alt="instagram" /></a>
        </div>
        <div style={styles.innerSocial}>
        <img src={tiktok} width={25} alt="tiktok" />
        </div>
    </div>

{/* <div style={styles.navbarStyle}>Social</div> */}
</div>
</>
  );
}

export default Navbar;
