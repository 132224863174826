import notfound from '../assets/404_pos.png';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import {useState, useEffect, } from 'react'

import "../styles/App.css"
function NotFound() {
  const [resized, setResized] = useState(window.innerWidth)
    useEffect(() => {
      window.addEventListener("resize", () => {
        setResized(window.innerWidth)
      })
    }, [resized])

  return (
    <div className="App">
  <Navbar resized={resized}/>
  <div style={{ 
      display:"flex", 
      alignItems:"center", 
      justifyContent:"center",
      height:"100vh", 
      width:"100%",
     }}>
  <img width={"60%"} src={notfound} alt="notfound"/>
    </div>
    <Footer/>
    </div>

  );
}

export default NotFound;
