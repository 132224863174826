import Btn from "./Btn"
const Cards = (props) => {

    return (
        <>
<div style={{display:"flex", padding:0, justifyContent:"space-evenly", flexDirection: "row", width:"100%"}}>
{(props.latest) && <div style={{position:"absolute", fontFamily:"Aloja", textAlign:"left", padding:"4px 10px 4px 10px", background:"#ffee3b" }}>Latest</div>}
<div style={{padding:10}}>
<img src={props.src} width="100%" alt={props.title}/>
<p style={{fontFamily:"Aloja", textAlign:"left", marginTop:20, color:"black", fontSize: (props.resized > 904) ? 24 : (props.resized < 630 && props.resized > 600) ? 14 : (props.resized < 600) ? 24 : 16}}>{props.title}</p>
<p style={{textAlign:"left", fontFamily:"bodoni", marginTop:-10}}>
{props.desc}
</p>

<a href={props.url}>
<Btn 
width={props.buttonWidth} btnTitle={props.btnTitle}/>
</a>
</div>
</div>
</>
    )
}

export default Cards;
