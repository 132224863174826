const Header = (props) => {
    const styles = {
        titleContainer:{
          fontFamily:"Aloja",
          fontSize:(props.resized < 1005) ? 35 : 45,
          textAlign:"left",
          width:"100%",
          height:"fit-content",
          
          // transform: (resized < 1005) ? "scale(.8)" : "scale(1)"
        },
    
        innerContainer: {
          display:"flex",
          flexDirection:"column",
          justifyContent:"center",
          margin:"0 auto",
          padding: (props.resized < 600) ? 20 : 100,
        },
    
      }

    return(
        <div style={styles.titleContainer}>
        <div style={styles.innerContainer}>
    
        {/* <span style={{...styles.highlight, height:16, fontSize:22, marginTop:-100, zIndex:-1}}>THE ORGANIC REVOLUTION:</span> */}
          <span style={{fontSize:15, height:20, background:"#ffee3b", rotate:"0deg", marginTop:20, width:"fit-content"}}>THE ORGANIC REVOLUTION:</span> 
    
        {
          (props.resized < 805) ?
          <>
          <span style={{fontSize:23, height:30, background:"#ffee3b", rotate:"2deg", marginTop:10, marginBottom:10, width:"fit-content"}}><p style={{fontSize:"100%", rotate:"-2deg", padding:0, margin:0}}>WHY EATING ORGANIC</p></span> 
          <span style={{fontSize:23, height:30, background:"#ffee3b", rotate:"2deg",  marginTop:3,  width:"fit-content"}}><p style={{fontSize:"100%", rotate:"-2deg", padding:0, margin:0}}>FOOD IS THE WAY TO GO</p></span> 
          </>
          :
          (props.resized >= 805 && props.resized <= 964) ?
          <span style={{fontSize:28, height:30, background:"#ffee3b", rotate:"1deg", marginTop:10, marginBottom:10, width:"fit-content"}}><p style={{fontSize:"100%",rotate:"-1deg", padding:0, margin:0}}>WHY EATING ORGANIC FOOD IS THE WAY TO GO</p></span> 
            :
          <span style={{fontSize:35, height:30, background:"#ffee3b", rotate:"1deg", marginTop:10, marginBottom:10, width:"fit-content"}}><p style={{fontSize:"100%",rotate:"-1deg", padding:0, margin:0}}>WHY EATING ORGANIC FOOD IS THE WAY TO GO</p></span> 
        }
      <p style={{fontSize:"60%", padding:0, marginBottom:0}}>
      JIMMY THE CHEF EXPLAINS WHY ORGANIC FOOD IS THE KEY TO A HEALTHY BODY AND A SUSTAINABLE PLANET AND SHARES SOME DELICIOUS ORGANIC RECIPES TO TRY.
    </p>
    
    <p style={{fontFamily:"bodoni", fontSize:"50%"}}>
    Chef Jimmy shares his delicious organic recipes that you can try at home. Organic food is grown without the use of synthetic pesticides and fertilisers, which makes it healthier for both the environment and us, the consumers. By choosing organic food, you are not only taking care of your health but also contributing to a sustainable future. Chef Jimmy's recipes are not only delicious but also easy to make, so you can enjoy healthy and sustainable meals without compromising on taste.
    </p>
    </div>

    <div style={{display:"flex", height:"70px", justifyContent:"center", width:"100%",}}>
    <div 
    className="arrow"
    style={{
      position:"absolute",
      width:30,
      height:30,
      borderRight:"7px solid #ffee3b",
      borderBottom:"7px solid #ffee3b",
      rotate:"45deg",
      animation:"arrowAnim 1s infinite ease-in-out"
    }}></div>

    </div>
    </div>
    )
}

export default Header;